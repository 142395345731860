/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ContentFragment } from '@oneaudi/falcon-tools';
import { ButtonProps } from '@audi/audi-ui-react';
import {
  Alignment,
  ButtonElement,
  ButtonElementContent,
  ButtonsContent,
  OpenIn,
  TextTwoLayout,
  ThemeColor,
} from '../../types';

export interface EditorJsonContent extends ButtonsContent {
  __type?: 'editor.json';
}

export interface FalconContent extends Partial<ContentFragment> {
  __type: 'aem-headless';
  fields: {
    buttons: [];
    paddingTop: boolean;
    alignment: Alignment;
    themeColor: ThemeColor;
    buttonCombinations: string[] | number[];
  };
}
export interface ButtonElementFalcon {
  fields: {
    title: string;
    firstVariant?: ButtonProps['variant'];
    firstLabel: string;
    firstUrl: string;
    textTwoLayout?: TextTwoLayout | 'horizontal';
    firstAriaLabel?: string;
    firstOpenIn?: OpenIn | 'same';
    secondVariant?: ButtonProps['variant'];
    secondLabel: string | null;
    secondUrl: string | null;
    secondAriaLabel?: string;
    secondOpenIn?: OpenIn | 'same';
    thirdVariant?: ButtonProps['variant'];
    thirdLabel: string | null;
    thirdUrl: string | null;
    thirdAriaLabel?: string;
    thirdOpenIn?: OpenIn | 'same';
  };
}

function normalizeButtonElement(buttons: ButtonElementFalcon[]): ButtonElement[] {
  if (!buttons) {
    return [];
  }

  return buttons.map((button: ButtonElementFalcon) => {
    const { fields } = button;
    const {
      title,
      firstVariant,
      firstLabel,
      firstAriaLabel,
      firstOpenIn,
      textTwoLayout,
      firstUrl,
      secondVariant,
      secondLabel,
      secondAriaLabel,
      secondOpenIn,
      secondUrl,
      thirdVariant,
      thirdLabel,
      thirdAriaLabel,
      thirdOpenIn,
      thirdUrl,
    } = fields;

    const buttonContent: ButtonElementContent[] = [];
    if (firstLabel && firstUrl) {
      buttonContent.push({
        variant: firstVariant,
        label: firstLabel,
        ariaLabel: firstAriaLabel,
        openIn: firstOpenIn,
        url: firstUrl,
      });
    }
    if (secondLabel && secondUrl) {
      buttonContent.push({
        variant: secondVariant,
        label: secondLabel,
        ariaLabel: secondAriaLabel,
        openIn: secondOpenIn,
        url: secondUrl,
      });
    }
    if (thirdLabel && thirdUrl) {
      buttonContent.push({
        variant: thirdVariant,
        label: thirdLabel,
        ariaLabel: thirdAriaLabel,
        openIn: thirdOpenIn,
        url: thirdUrl,
      });
    }
    return {
      title,
      buttonContent,
      textTwoLayout,
    };
  });
}

export function mapContent(
  content: FalconContent | EditorJsonContent | undefined,
): EditorJsonContent {
  if (!content || Object.keys(content).length === 0) {
    // eslint-disable-next-line no-console
    console.warn('No Editor content found for Buttons FA');
    return {} as EditorJsonContent;
  }
  if (content.__type !== 'aem-headless') {
    return content;
  }
  return {
    __type: 'editor.json',
    buttonCombinations: content.fields.buttonCombinations,
    buttons: normalizeButtonElement(content.fields.buttons),
    paddingTop: content.fields.paddingTop,
    alignment: content.fields.alignment,
    themeColor: content.fields.themeColor,
  };
}
