/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Theme as AudiTheme } from '@audi/audi-ui-react';

import { APP_ID, NODE_ENVIRONMENT, STAGE } from '../environment';
import { ButtonElement, ButtonsContent } from '../types';

export const isDarkTheme = (theme: AudiTheme): boolean => theme.name === 'Audi Dark Theme';

export const APP_NAME = APP_ID.replace('oneaudi-feature-app-', '').replace(/-/g, ' ');

export const enum QueryParam {
  Test = 't',
  Debug = 'd_fa',
}

export const getParam = (paramName: string): string | null => {
  try {
    if (BROWSER) return new URLSearchParams(window.location.search.substr(1)).get(paramName);
    return null;
  } catch (e) {
    return null;
  }
};

export const isDemoUrl = (): boolean => {
  if (BROWSER) {
    const currentUrl: URL = new URL(window?.location?.href);
    const hostnamePattern = /^fa-buttons\.cdn\.prod\.arcade\.apps\.one\.audi$/;
    const pathnamePattern = /\/demo\//;

    const hostnameMatches = hostnamePattern.test(currentUrl?.hostname);
    const pathnameMatches = pathnamePattern.test(currentUrl?.pathname);

    if (hostnameMatches && pathnameMatches) {
      return true;
    }
  }
  return false;
};

export const isTestMode = (): boolean => getParam(QueryParam.Test) === 'true';

export const isDevMode = NODE_ENVIRONMENT === 'development';

export const isStageDev = STAGE === 'dev' && NODE_ENVIRONMENT === 'production';

export const isDebugMode = (): boolean => isDevMode || getParam(QueryParam.Debug) === 'true';

export const isEmpty = (obj: unknown): boolean => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <boolean>obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype
  );
};

export const defaultCombinations = {
  // set default of 'buttonThreePrimaryFirst'
  keyName: 'buttonThreePrimaryFirst',
};

export const filterPropKeys = (keyName: string, obj: ButtonsContent) => {
  // filter out fields in ButtonsContent that are button combinations
  return Object.keys(obj).filter(
    // @ts-ignore
    (key) => key.includes(keyName) && obj[key] !== undefined,
  );
};

export function getSelectedButtonCombination(obj: ButtonsContent): ButtonElement {
  const keyName: number = Number(obj?.buttonCombinations);

  const buttonCombination: ButtonElement = obj?.buttons?.filter((_, index) => index === keyName)[0];

  return buttonCombination || null;
}

/*
 * FOR NEMO
 * In NEMO, the layer templates include unneeded resources such as NEMO-HEADER
 * and NEMO-FOOTER as well as CSR Integrator. For the use inside one-layer
 * it is required to only get the content without all other resources. This can be accomplished
 * by loading the 'headless' variant of a template
 * */

export function setHeadlessUrl(url: string): string {
  const isHeadlessLink = url.includes('.headless.');
  const hasHtmlReference = url.includes('.html');
  const htmlIsSuffix = url.charAt(url.length - 1) === 'l';
  return hasHtmlReference && htmlIsSuffix && !isHeadlessLink
    ? url.replace('.html', '.headless.html')
    : url;
}
